<template>
    <div id="page-container">

        <!-- Main Container -->
        <main id="main-container">
            <!-- Page Content -->
            <div class="bg-image" style="background-image: url('assets/auth-bg.jpg');">
                <div class="row g-0 bg-primary-dark-op">
                    <!-- Meta Info Section -->
                    <div class="hero-static col-lg-4 d-none d-lg-flex flex-column justify-content-center">
                        <div class="p-4 p-xl-5 flex-grow-1 d-flex align-items-center">
                            <div class="w-100">
                                <a class="link-fx fw-semibold fs-2 text-white" href="/">
                                    <img class="logo logo-light" src="/assets/images/logo/logo-light.png" alt="QRCBN Logo" />
                                </a>
                                <p class="text-white-75 me-xl-8 mt-2">
                                    Selamat datang di QRCBN. Daftarkan semua buku anda agar mudah ditemukan semua orang.
                                </p>
                            </div>
                        </div>
                        <div class="p-4 p-xl-5 d-xl-flex justify-content-between align-items-center fs-sm">
                            <p class="fw-medium text-white-50 mb-0">
                                <strong>QRCBN 2023</strong> &copy; <span data-toggle="year-copy"></span>
                            </p>
                            <!-- <ul class="list list-inline mb-0 py-2">
                                <li class="list-inline-item">
                                    <a class="text-white-75 fw-medium" href="javascript:void(0)">Legal</a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="text-white-75 fw-medium" href="javascript:void(0)">Contact</a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="text-white-75 fw-medium" href="javascript:void(0)">Terms</a>
                                </li>
                            </ul> -->
                        </div>
                    </div>
                    <!-- END Meta Info Section -->

                    <!-- Main Section -->
                    <div class="hero-static col-lg-8 d-flex flex-column align-items-center bg-body-extra-light">
                        <div class="p-3 w-100 d-lg-none text-center">
                            <a class="link-fx fw-semibold fs-3 text-dark" href="/">
                                <img class="logo logo-light" src="/assets/images/logo/logo-dark.png" alt="QRCBN Logo" />
                            </a>
                        </div>
                        <div class="p-4 w-100 flex-grow-1 d-flex align-items-center">
                            <router-view/>
                        </div>
                        <div
                            class="px-4 py-3 w-100 d-lg-none d-flex flex-column flex-sm-row justify-content-between fs-sm text-center text-sm-start">
                            <p class="fw-medium text-black-50 py-2 mb-0">
                                <strong>QRCBN</strong> &copy; <span data-toggle="year-copy">2023</span>
                            </p>
                            <!-- <ul class="list list-inline py-2 mb-0">
                                <li class="list-inline-item">
                                    <a class="text-muted fw-medium" href="javascript:void(0)">Legal</a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="text-muted fw-medium" href="javascript:void(0)">Contact</a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="text-muted fw-medium" href="javascript:void(0)">Terms</a>
                                </li>
                            </ul> -->
                        </div>
                    </div>
                    <!-- END Main Section -->
                </div>
            </div>
            <!-- END Page Content -->
        </main>
        <!-- END Main Container -->
    </div>
</template>

<script>
export default {
    components: {},
};
</script>