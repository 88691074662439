<template>
  <div id="page-container"
    class="sidebar-o sidebar-dark enable-page-overlay side-scroll page-header-fixed main-content-narrow">
    <!-- Side Overlay-->
    <aside id="side-overlay">
      <!-- Side Header -->
      <div class="content-header border-bottom">
        <!-- User Avatar -->
        <a class="img-link me-1" href="javascript:void(0)">
          <img class="img-avatar img-avatar32" src="/favico.png" alt="">
        </a>
        <!-- END User Avatar -->

        <!-- User Info -->
        <div class="ms-2">
          <a class="text-dark fw-semibold fs-sm" href="javascript:void(0)">John Smith</a>
        </div>
        <!-- END User Info -->

        <!-- Close Side Overlay -->
        <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
        <a class="ms-auto btn-sm btn-alt-danger" href="javascript:void(0)" data-toggle="layout"
          data-action="side_overlay_close">
          <i class="fa fa-fw fa-times"></i>
        </a>
        <!-- END Close Side Overlay -->
      </div>
      <!-- END Side Header -->

      <!-- Side Content -->
      <div class="content-side">
        <!-- Side Overlay Tabs -->
        <div class="block block-transparent pull-x pull-t">
          <ul class="nav nav-tabs nav-tabs-block nav-justified" role="tablist">
            <li class="nav-item">
              <button type="button" class="nav-link active" id="so-overview-tab" data-bs-toggle="tab"
                data-bs-target="#so-overview" role="tab" aria-controls="so-overview" aria-selected="true">
                <i class="fa fa-fw fa-coffee text-gray opacity-75 me-1"></i> Overview
              </button>
            </li>
            <li class="nav-item">
              <button type="button" class="nav-link" id="so-sales-tab" data-bs-toggle="tab" data-bs-target="#so-sales"
                role="tab" aria-controls="so-sales" aria-selected="false">
                <i class="fa fa-fw fa-chart-line text-gray opacity-75 me-1"></i> Sales
              </button>
            </li>
          </ul>
          <div class="block-content tab-content overflow-hidden">
            <!-- Overview Tab -->
            <div class="tab-pane pull-x fade fade-left show active" id="so-overview" role="tabpanel"
              aria-labelledby="so-overview-tab" tabindex="0">
              <!-- Activity -->
              <div class="block block-transparent">
                <div class="block-header block-header-default">
                  <h3 class="block-title">Recent Activity</h3>
                  <div class="block-options">
                    <button type="button" class="btn-block-option" data-toggle="block-option" data-action="state_toggle"
                      data-action-mode="demo">
                      <i class="si si-refresh"></i>
                    </button>
                    <button type="button" class="btn-block-option" data-toggle="block-option"
                      data-action="content_toggle"></button>
                  </div>
                </div>
                <div class="block-content">
                  <!-- Activity List -->
                  <ul class="nav-items mb-0">
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-plus text-success"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">New sale ($15)</div>
                          <div>Admin Template</div>
                          <small class="text-muted">3 min ago</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-pencil-alt text-info"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">You edited the file</div>
                          <div>Documentation.doc</div>
                          <small class="text-muted">15 min ago</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-trash text-danger"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">Project deleted</div>
                          <div>Line Icon Set</div>
                          <small class="text-muted">4 hours ago</small>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <!-- END Activity List -->
                </div>
              </div>
              <!-- END Activity -->

              <!-- Online Friends -->
              <div class="block block-transparent">
                <div class="block-header block-header-default">
                  <h3 class="block-title">Online Friends</h3>
                  <div class="block-options">
                    <button type="button" class="btn-block-option" data-toggle="block-option" data-action="state_toggle"
                      data-action-mode="demo">
                      <i class="si si-refresh"></i>
                    </button>
                    <button type="button" class="btn-block-option" data-toggle="block-option"
                      data-action="content_toggle"></button>
                  </div>
                </div>
                <div class="block-content">
                  <!-- Users Navigation -->
                  <ul class="nav-items mb-0">
                    <li>
                      <a class="d-flex py-2" href="javascript:void(0)">
                        <div class="me-3 ms-2 overlay-container overlay-bottom">
                          <img class="img-avatar img-avatar48" src="assets/media/avatars/avatar6.jpg" alt="">
                          <span
                            class="overlay-item item item-tiny item-circle border border-2 border-white bg-success"></span>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">Megan Fuller</div>
                          <div class="text-muted">Copywriter</div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="d-flex py-2" href="javascript:void(0)">
                        <div class="me-3 ms-2 overlay-container overlay-bottom">
                          <img class="img-avatar img-avatar48" src="assets/media/avatars/avatar9.jpg" alt="">
                          <span
                            class="overlay-item item item-tiny item-circle border border-2 border-white bg-success"></span>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">Wayne Garcia</div>
                          <div class="text-muted">Web Developer</div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="d-flex py-2" href="javascript:void(0)">
                        <div class="me-3 ms-2 overlay-container overlay-bottom">
                          <img class="img-avatar img-avatar48" src="assets/media/avatars/avatar4.jpg" alt="">
                          <span
                            class="overlay-item item item-tiny item-circle border border-2 border-white bg-success"></span>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">Megan Fuller</div>
                          <div class="text-muted">Web Designer</div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="d-flex py-2" href="javascript:void(0)">
                        <div class="me-3 ms-2 overlay-container overlay-bottom">
                          <img class="img-avatar img-avatar48" src="assets/media/avatars/avatar1.jpg" alt="">
                          <span
                            class="overlay-item item item-tiny item-circle border border-2 border-white bg-warning"></span>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">Lori Moore</div>
                          <div class="text-muted">Photographer</div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="d-flex py-2" href="javascript:void(0)">
                        <div class="me-3 ms-2 overlay-container overlay-bottom">
                          <img class="img-avatar img-avatar48" src="assets/media/avatars/avatar13.jpg" alt="">
                          <span
                            class="overlay-item item item-tiny item-circle border border-2 border-white bg-warning"></span>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">Jose Parker</div>
                          <div class="text-muted">Graphic Designer</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                  <!-- END Users Navigation -->
                </div>
              </div>
              <!-- END Online Friends -->

              <!-- Quick Settings -->
              <div class="block block-transparent mb-0">
                <div class="block-header block-header-default">
                  <h3 class="block-title">Quick Settings</h3>
                  <div class="block-options">
                    <button type="button" class="btn-block-option" data-toggle="block-option"
                      data-action="content_toggle"></button>
                  </div>
                </div>
                <div class="block-content">
                  <!-- Quick Settings Form -->
                  <form action="be_pages_dashboard.html" method="POST" onsubmit="return false;">
                    <div class="mb-4">
                      <p class="fs-sm fw-semibold mb-2">
                        Online Status
                      </p>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" value="" id="so-settings-check1"
                          name="so-settings-check1" checked>
                        <label class="form-check-label fs-sm" for="so-settings-check1">Show your status to all</label>
                      </div>
                    </div>
                    <div class="mb-4">
                      <p class="fs-sm fw-semibold mb-2">
                        Auto Updates
                      </p>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" value="" id="so-settings-check2"
                          name="so-settings-check2" checked>
                        <label class="form-check-label fs-sm" for="so-settings-check2">Keep up to date</label>
                      </div>
                    </div>
                    <div class="mb-4">
                      <p class="fs-sm fw-semibold mb-1">
                        Application Alerts
                      </p>
                      <div class="space-y-2">
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" value="" id="so-settings-check3"
                            name="so-settings-check3" checked>
                          <label class="form-check-label fs-sm" for="so-settings-check3">Email Notifications</label>
                        </div>
                        <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" value="" id="so-settings-check4"
                            name="so-settings-check4" checked>
                          <label class="form-check-label fs-sm" for="so-settings-check4">SMS Notifications</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <p class="fs-sm fw-semibold mb-1">
                        API
                      </p>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" value="" id="so-settings-check5"
                          name="so-settings-check5" checked>
                        <label class="form-check-label fs-sm" for="so-settings-check5">Enable access</label>
                      </div>
                    </div>
                  </form>
                  <!-- END Quick Settings Form -->
                </div>
              </div>
              <!-- END Quick Settings -->
            </div>
            <!-- END Overview Tab -->

            <!-- Sales Tab -->
            <div class="tab-pane pull-x fade fade-right" id="so-sales" role="tabpanel" aria-labelledby="so-sales-tab"
              tabindex="0">
              <div class="block block-transparent mb-0">
                <!-- Stats -->
                <div class="block-content">
                  <div class="row items-push pull-t">
                    <div class="col-6">
                      <div class="fs-sm fw-semibold text-uppercase">Sales</div>
                      <a class="fs-lg" href="javascript:void(0)">22.030</a>
                    </div>
                    <div class="col-6">
                      <div class="fs-sm fw-semibold text-uppercase">Balance</div>
                      <a class="fs-lg" href="javascript:void(0)">$4.589,00</a>
                    </div>
                  </div>
                </div>
                <!-- END Stats -->

                <!-- Today -->
                <div class="block-content block-content-full block-content-sm bg-body-light">
                  <div class="row">
                    <div class="col-6">
                      <span class="fs-sm fw-semibold text-uppercase">Today</span>
                    </div>
                    <div class="col-6 text-end">
                      <span class="ext-muted">$996</span>
                    </div>
                  </div>
                </div>
                <div class="block-content">
                  <ul class="nav-items push">
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-plus text-success"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">New sale! + $249</div>
                          <small class="text-muted">3 min ago</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-plus text-success"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">New sale! + $129</div>
                          <small class="text-muted">50 min ago</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-plus text-success"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">New sale! + $119</div>
                          <small class="text-muted">2 hours ago</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-plus text-success"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">New sale! + $499</div>
                          <small class="text-muted">3 hours ago</small>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <!-- END Today -->

                <!-- Yesterday -->
                <div class="block-content block-content-full block-content-sm bg-body-light">
                  <div class="row">
                    <div class="col-6">
                      <span class="fs-sm fw-semibold text-uppercase">Yesterday</span>
                    </div>
                    <div class="col-6 text-end">
                      <span class="text-muted">$765</span>
                    </div>
                  </div>
                </div>
                <div class="block-content">
                  <ul class="nav-items push">
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-plus text-success"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">New sale! + $249</div>
                          <small class="text-muted">26 hours ago</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-minus text-danger"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">Product Purchase - $50</div>
                          <small class="text-muted">28 hours ago</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-plus text-success"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">New sale! + $119</div>
                          <small class="text-muted">29 hours ago</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-minus text-danger"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">Paypal Withdrawal - $300</div>
                          <small class="text-muted">37 hours ago</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-plus text-success"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">New sale! + $129</div>
                          <small class="text-muted">39 hours ago</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-plus text-success"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">New sale! + $119</div>
                          <small class="text-muted">45 hours ago</small>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a class="text-dark d-flex py-2" href="javascript:void(0)">
                        <div class="flex-shrink-0 me-3 ms-2">
                          <i class="fa fa-fw fa-plus text-success"></i>
                        </div>
                        <div class="flex-grow-1 fs-sm">
                          <div class="fw-semibold">New sale! + $499</div>
                          <small class="text-muted">46 hours ago</small>
                        </div>
                      </a>
                    </li>
                  </ul>

                  <!-- More -->
                  <div class="text-center">
                    <a class="btn-sm btn-alt-secondary" href="javascript:void(0)">
                      <i class="fa fa-arrow-down opacity-50 me-1"></i> Load More..
                    </a>
                  </div>
                  <!-- END More -->
                </div>
                <!-- END Yesterday -->
              </div>
            </div>
            <!-- END Sales Tab -->
          </div>
        </div>
        <!-- END Side Overlay Tabs -->
      </div>
      <!-- END Side Content -->
    </aside>
    <!-- END Side Overlay -->

    <!-- Sidebar -->
    <!--
          Sidebar Mini Mode - Display Helper classes

          Adding 'smini-hide' class to an element will make it invisible (opacity: 0) when the sidebar is in mini mode
          Adding 'smini-show' class to an element will make it visible (opacity: 1) when the sidebar is in mini mode
              If you would like to disable the transition animation, make sure to also add the 'no-transition' class to your element

          Adding 'smini-hidden' to an element will hide it when the sidebar is in mini mode
          Adding 'smini-visible' to an element will show it (display: inline-block) only when the sidebar is in mini mode
          Adding 'smini-visible-block' to an element will show it (display: block) only when the sidebar is in mini mode
      -->
    <nav id="sidebar" aria-label="Main Navigation">
      <!-- Side Header -->
      <div class="content-header">
        <!-- Logo -->
        <a class="fw-semibold text-dual" href="/dashboard">
          <span class="smini-visible">
            <i class="fa fa-circle-notch text-primary"></i>
          </span>
          <span class="smini-hide fs-5 tracking-wider">QRCBN</span>
        </a>
        <!-- END Logo -->

        <!-- Extra -->
        <div>

          <!-- Close Sidebar, Visible only on mobile screens -->
          <!-- Layout API, functionality initialized in Template._uiApiLayout() -->
          <a class="d-lg-none btn-sm btn-alt-secondary ms-1" data-toggle="layout" data-action="sidebar_close"
            href="javascript:void(0)">
            <i class="fa fa-fw fa-times"></i>
          </a>
          <!-- END Close Sidebar -->
        </div>
        <!-- END Extra -->
      </div>
      <!-- END Side Header -->

      <!-- Sidebar Scrolling -->
      <div class="js-sidebar-scroll">
        <!-- Side Navigation -->
        <div class="content-side">
          <ul class="nav-main">
            <li class="nav-main-item">
              <a class="nav-main-link" href="/dashboard">
                <i class="nav-main-link-icon si si-speedometer"></i>
                <span class="nav-main-link-name">Dashboard</span>
              </a>
            </li>

            <li class="nav-main-item">
              <a class="nav-main-link" href="/library">
                <i class="nav-main-link-icon si si-layers"></i>
                <span class="nav-main-link-name">Perpustakaan</span>
              </a>
            </li>

            <li class="nav-main-item">
              <a class="nav-main-link" href="/publish">
                <i class="nav-main-link-icon si si-note"></i>
                <span class="nav-main-link-name">Terbit Baru</span>
              </a>
            </li>

            <li class="nav-main-item">
              <a class="nav-main-link" href="/profile">
                <i class="nav-main-link-icon si si-user"></i>
                <span class="nav-main-link-name">Profile</span>
              </a>
            </li>

            <li class="nav-main-item">
              <a class="nav-main-link" href="#" @click="logout">
                <i class="nav-main-link-icon si si-logout"></i>
                <span class="nav-main-link-name">Log Out</span>
              </a>
            </li>
          </ul>
        </div>
        <!-- END Side Navigation -->
      </div>
      <!-- END Sidebar Scrolling -->
    </nav>
    <!-- END Sidebar -->

    <!-- Header -->
    <header id="page-header">
      <!-- Header Content -->
      <div class="content-header">
        <!-- Left Section -->
        <div class="d-flex align-items-center">
          <!-- Toggle Sidebar -->
          <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
          <button type="button" class="btn-sm btn-alt-secondary me-2 d-lg-none" data-toggle="layout"
            data-action="sidebar_toggle">
            <i class="fa fa-fw fa-bars"></i>
          </button>
          <!-- END Toggle Sidebar -->

          <!-- Toggle Mini Sidebar -->
          <!-- Layout API, functionality initialized in Template._uiApiLayout()-->
          <button type="button" class="btn-sm btn-alt-secondary me-2 d-none d-lg-inline-block" data-toggle="layout"
            data-action="sidebar_mini_toggle">
            <i class="fa fa-fw fa-ellipsis-v"></i>
          </button>
          <!-- END Toggle Mini Sidebar -->

          <!-- Open Search Section (visible on smaller screens) -->
          <!-- Layout API, functionality initialized in Template._uiApiLayout() -->

        </div>
        <!-- END Left Section -->

        <!-- Right Section -->
        <div class="d-flex align-items-center">
          <!-- User Dropdown -->
          <div class="dropdown d-inline-block ms-2">
            <button type="button" class="btn-sm btn-alt-secondary d-flex align-items-center"
              id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img class="rounded-circle" :src="user.image" v-if="user.image" alt="Header Avatar" style="width: 21px;">
              <img class="rounded-circle" src="/favico.png" alt="Header Avatar" style="width: 21px;" v-else>
              <span class="d-none d-sm-inline-block ms-2">{{ user.name }}</span>
              <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block opacity-50 ms-1 mt-1"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-md dropdown-menu-end p-0 border-0"
              aria-labelledby="page-header-user-dropdown">
              <div class="p-3 text-center bg-body-light border-bottom rounded-top">
                <img class="img-avatar img-avatar48 img-avatar-thumb" :src="user.image" v-if="user.image" alt="">
                <img class="img-avatar img-avatar48 img-avatar-thumb" src="/favico.png" alt="" v-else>
                <p class="mt-2 mb-0 fw-medium">{{ user.type }} {{ user.name }}</p>
              </div>
              <div class="p-2">

                <a class="dropdown-item d-flex align-items-center justify-content-between" href="/profile">
                  <span class="fs-sm fw-medium">Profile</span>
                </a>
              </div>
              <div role="separator" class="dropdown-divider m-0"></div>
              <div class="p-2">
                <button class="dropdown-item d-flex align-items-center justify-content-between" @click="logout">
                  <span class="fs-sm fw-medium">Log Out</span>
                </button>
              </div>
            </div>
          </div>
          <!-- END User Dropdown -->

        </div>
        <!-- END Right Section -->
      </div>
      <!-- END Header Content -->
    </header>
    <!-- END Header -->

    <!-- Main Container -->
    <main id="main-container">
      <div class="container">
        <router-view />
      </div>
    </main>
    <!-- END Main Container -->

    <!-- Footer -->
    <footer id="page-footer" class="bg-body-light">
      <div class="content py-3">
        <div class="row fs-sm">
          <div class="col-sm-6 order-sm-1 py-1 text-center text-sm-start">
            <a class="fw-semibold" href="https://www.qrcbn.com" target="_blank">QRCBN</a> &copy; <span
              data-toggle="year-copy"></span>
          </div>
        </div>
      </div>
    </footer>
    <!-- END Footer -->

    <!-- Survey Container -->

    <v-dialog v-model="surveyDialog" width="auto">
      <v-card max-width="400" prepend-icon="mdi-update" class="p-3">
        <v-card-text>
          Untuk dapat menggunakan sistem QRCBN mohon luangkan waktu sebentar untuk mengisi survey singkat agar kami dapat lebih baik lagi melayani Anda.
        </v-card-text>
        <v-card-actions>
          <a :href="'https://survey.qrcbn.com?key='+surveybtoa">
            <v-btn variant="flat" block>
              Mulai Survey
            </v-btn>
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- End Survey Container -->

  </div>
  <!-- END Page Container -->
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    surveyDialog: false,
    surveybtoa: '',
    active: "home",
    drawer: false,
    items: [
      { title: "Dashboard", icon: "mdi-home-city", link: "dashboard" },
      { title: "Library", icon: "mdi-book-open", link: "library" },
      { title: "Publish a Book", icon: "mdi-publish", link: "publish" },
      { title: "Profile", icon: "mdi-account-group-outline", link: "profile" },
    ],
    user: []
  }),
  mounted() {
    this.getData()
    this.checkSurvey()

    const plugin = document.createElement("script");
    plugin.setAttribute(
      "src",
      "/oneui/assets/js/oneui.app.min.js"
    );
    plugin.async = true;
    document.head.appendChild(plugin);

  },
  methods: {

    getData() {
      this.loading_data = true;

      axios
        .get("account", {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.user = response.data.data.user;
          this.surveybtoa = btoa(this.user.email);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading_data = false));
    },

    checkSurvey() {
      this.loading_data = true;

      axios
        .get("survey", {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.surveyDialog = response.data.survey
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading_data = false));
    },

    logout() {
      const token = localStorage.getItem("access_token");
      const AuthStr = "Bearer " + token;

      axios
        .post(
          "logout",
          { api_token: AuthStr },
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: AuthStr,
            },
          }
        )
        .then((response) => {
          console.log(response);
          localStorage.clear();
          this.$router.go();
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style>
.dashboard-logo {
  height: 30px !important;
}

.vs-input-parent--shadow .vs-input__icon {
  z-index: 0 !important;
}

.vs-input-parent--shadow .vs-input-content .vs-input__affects {
  z-index: 0 !important;
}

.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}
</style>

