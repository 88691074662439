<template>
	<!-- Document Wrapper-->
	<div class="wrapper clearfix" id="wrapperParallax">
		<header class="header header-transparent header-sticky">
			<nav class="navbar navbar-sticky navbar-expand-lg" id="primary-menu">
				<div class="container">
					<a class="logo navbar-brand" href="/"><img class="logo logo-dark"
							src="/assets/images/logo/logo-dark.png" alt="QRCBN Logo" /><img class="logo logo-light"
							src="/assets/images/logo/logo-light.png" alt="QRCBN Logo" /></a>
					<button class="navbar-toggler collapsed" type="button" data-toggle="collapse"
						data-target="#navbarContent" aria-expanded="false"><span
							class="navbar-toggler-icon"></span></button>
					<div class="collapse navbar-collapse" id="navbarContent">
						<ul class="navbar-nav ml-auto">
							<li class="nav-item active"><a class="nav-link" data-scroll="scrollTo" href="/#hero">Home</a></li>
							<li class="nav-item"><a class="nav-link" data-scroll="scrollTo" href="/#benefit">Keuntungan</a></li>
							<li class="nav-item"><a class="nav-link" data-scroll="scrollTo" href="/#about">Tentang</a></li>
							<li class="nav-item"><a class="nav-link" data-scroll="scrollTo" href="/#HowItWorks">Cara Kerja</a></li>
							<li class="nav-item"><router-link to="scan">Scan QR Code</router-link></li>
							<!-- <li class="nav-item"><a class="nav-link" href="https://market.qrcbn.com">Market</a></li> -->
						</ul>

						<div class="module-container">
							<!--module-btn-->
							<div class="module module-cta">
								<a class="btn" data-scroll="scrollTo" href="/login">
									<span>Masuk</span>
								</a>
							</div>
						</div>
						<!-- End Module Container  -->

					</div>
					<!-- End .nav-collapse-->
				</div>
				<!-- End .container-->
			</nav>
			<!-- End .navbar-->
		</header>
		<router-view></router-view>
		<!-- 
      Footer #1
      ============================================= 
      -->
		<footer class="footer" id="footer">
			<div class="footer-top">
				<div class="container">
					<div class="row">
						<div class="col-12 col-md-12 col-lg-3">
							<div class="footer-logo"><a class="logo" href="index.html"><img class="logo logo-light"
										src="/assets/images/logo/logo-footer.png" alt="QRCBN Logo" /></a></div>
						</div>
						<div class="col-12 col-md-12 col-lg-7">
							<ul class="list-unstyled footer-navigation">
								<li><a href="/">Home</a></li>
								<li><a href="/#benefit">Keuntungan</a></li>
								<li><a href="/#about">Tentang </a></li>
								<li><a href="/#HowItWorks">Cara Kerja</a></li>
								<li><a href="/privacy-policy">Kebijakan Privasi</a></li>
								<!-- <li><a href="/syarat-dan-ketentuan">Syarat & Ketentuan</a></li> -->
							</ul>
						</div>
						<div class="col-12 col-md-12 col-lg-2">
							<div class="footer-social">
								<ul class="list-unstyled">
									<li> <a href="https://www.facebook.com/profile.php?id=100080461844507" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
									<li> <a href="https://www.instagram.com/qrcbooknumber/" target="_blank"><i class="fab fa-instagram"></i></a></li>
									<!-- <li> <a href="javascript:void(0)"><i class="fab fa-tiktok"></i></a></li> -->
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-bottom">
				<div class="container">
					<hr />
				</div>
				<div class="container">
					<div class="row">
						<div class="col-12 col-md-12 col-lg-12 text--center">
							<div class="footer-copyright"><span>2023 &copy; <a
										href="https://www.qrcbn.com">QRCBN</a>.
									All rights reserved.</span></div>
						</div>
					</div>
				</div>
			</div>
			<!-- End .container  -->
		</footer>
	</div>
</template>

<script>
export default {
	name: "App",

	data: () => ({
		//
	}),
};
</script>
