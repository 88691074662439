var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper clearfix",attrs:{"id":"wrapperParallax"}},[_c('header',{staticClass:"header header-transparent header-sticky"},[_c('nav',{staticClass:"navbar navbar-sticky navbar-expand-lg",attrs:{"id":"primary-menu"}},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":"scan"}},[_vm._v("Scan QR Code")])],1)]),_vm._m(6)])])])]),_c('router-view'),_vm._m(7)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"logo navbar-brand",attrs:{"href":"/"}},[_c('img',{staticClass:"logo logo-dark",attrs:{"src":"/assets/images/logo/logo-dark.png","alt":"QRCBN Logo"}}),_c('img',{staticClass:"logo logo-light",attrs:{"src":"/assets/images/logo/logo-light.png","alt":"QRCBN Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler collapsed",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarContent","aria-expanded":"false"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item active"},[_c('a',{staticClass:"nav-link",attrs:{"data-scroll":"scrollTo","href":"/#hero"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-scroll":"scrollTo","href":"/#benefit"}},[_vm._v("Keuntungan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-scroll":"scrollTo","href":"/#about"}},[_vm._v("Tentang")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"data-scroll":"scrollTo","href":"/#HowItWorks"}},[_vm._v("Cara Kerja")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-container"},[_c('div',{staticClass:"module module-cta"},[_c('a',{staticClass:"btn",attrs:{"data-scroll":"scrollTo","href":"/login"}},[_c('span',[_vm._v("Masuk")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer",attrs:{"id":"footer"}},[_c('div',{staticClass:"footer-top"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12 col-lg-3"},[_c('div',{staticClass:"footer-logo"},[_c('a',{staticClass:"logo",attrs:{"href":"index.html"}},[_c('img',{staticClass:"logo logo-light",attrs:{"src":"/assets/images/logo/logo-footer.png","alt":"QRCBN Logo"}})])])]),_c('div',{staticClass:"col-12 col-md-12 col-lg-7"},[_c('ul',{staticClass:"list-unstyled footer-navigation"},[_c('li',[_c('a',{attrs:{"href":"/"}},[_vm._v("Home")])]),_c('li',[_c('a',{attrs:{"href":"/#benefit"}},[_vm._v("Keuntungan")])]),_c('li',[_c('a',{attrs:{"href":"/#about"}},[_vm._v("Tentang ")])]),_c('li',[_c('a',{attrs:{"href":"/#HowItWorks"}},[_vm._v("Cara Kerja")])]),_c('li',[_c('a',{attrs:{"href":"/privacy-policy"}},[_vm._v("Kebijakan Privasi")])])])]),_c('div',{staticClass:"col-12 col-md-12 col-lg-2"},[_c('div',{staticClass:"footer-social"},[_c('ul',{staticClass:"list-unstyled"},[_c('li',[_c('a',{attrs:{"href":"https://www.facebook.com/profile.php?id=100080461844507","target":"_blank"}},[_c('i',{staticClass:"fab fa-facebook-f"})])]),_c('li',[_c('a',{attrs:{"href":"https://www.instagram.com/qrcbooknumber/","target":"_blank"}},[_c('i',{staticClass:"fab fa-instagram"})])])])])])])])]),_c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"container"},[_c('hr')]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12 col-lg-12 text--center"},[_c('div',{staticClass:"footer-copyright"},[_c('span',[_vm._v("2023 © "),_c('a',{attrs:{"href":"https://www.qrcbn.com"}},[_vm._v("QRCBN")]),_vm._v(". All rights reserved.")])])])])])])])
}]

export { render, staticRenderFns }